<template>
  <div id="mui-player"></div>
</template>

<script>
import 'mui-player/dist/mui-player.min.css'
import MuiPlayer from 'mui-player'
import MuiPlayerDesktopPlugin from 'mui-player-desktop-plugin'
export default {
  name: "videomuiPlayer",
  components: {MuiPlayer},
  props: ['videourl'],
  data() {
    return {
      mp: ''
    }
  },


  watch: {
    videourl() {
      //切换视频地址
      // this.mp.reloadUrl(this.videourl.videoUrl)
      // this.mp.reloadUrl()
    }
  },

  methods: {
    init() {
      const that = this
      console.log(that.videourl)

      that.mp = new MuiPlayer({
        container: '#mui-player',
        title: '',
        // poster: that.videourl,
        src: that.videourl,
        autoplay: true,
        loop: true,
        volume: 0,
        plugins: [
          new MuiPlayerDesktopPlugin({
            leaveHiddenControls: true,
            customSetting: [
              {
                functions: '清晰度',
                model: 'select',
                show: true,
                zIndex: 0,
                childConfig: [
                  // {functions: '蓝光1080P'},
                  // {functions: '超清'},
                  {functions: '超清', selected: true},
                  // {functions: '标清'},
                ],
                onToggle: function (data, selected, back) {
                  // Action
                }
              }
            ],
          })
        ]
      })
    },
  },

  mounted() {
    this.init()

  },
}
</script>


<style scoped>
#mui-player {
  height: 400px;
}

.m-player .mplayer-footer {
  display: none !important;
}
</style>