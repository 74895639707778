<template>
  <div class="home">

    <!--<div class="ce" data-aos="fade-up" data-aos-duration="1000"> </div>-->

    <!--导航-->
    <div class="nav-box">
      <div class="nav-box1">


        <h1 class="logo-box">
          <img src="@/assets/img/logo.png" alt="">
        </h1>

        <div class="nav-right">
          <div class="nav-title" @click="goposition1(item.idb)" :data-to="item.idb" v-for="item in navtext">{{
              item.text
            }}
          </div>
          <div class="nav-title phone-box">
            <div><img src="@/assets/img/phone-icon.png" alt=""></div>
            <div>{{ phone }}</div>
          </div>
        </div>
      </div>
    </div>

    <!--视频背景-->
    <div class="video-box" id="nav1">
      <div class="shade">
        <h3>中关汇成 专属技术合作商 专业服务IT领域</h3>
        <p>APP定制开发·智能软硬件研发·小程序定制开发·网站及微站HTML5定制开发·服务运维</p>
      </div>
      <video src="@/assets/img/bg.mp4" loop autoplay width="100%" muted preload="metadata"></video>
    </div>

    <!--关于我们-->
    <div id="nav2" class="about-box">
      <div class="about-center-box">
        <!--        1-->
        <div class="about">
          <div class="about-1"><img src="@/assets/img/1.png" alt=""></div>
          <div class="about-2">
            <h3>ABOUT <br>US</h3>
            <h5>北京中关汇成信息技术有限公司</h5>
            <p>
              北京中关汇成信息技术有限公司〈简称“中关汇成”）成立于2019年，前身是金领园教育集团项目部。是一家以技术为核心驱动的研发企业。致力于成为具有影响的数字技术服务领导企业，为企业提供数字化服务的可信赖合作伙伴。秉承10年磨一剑的精神，以打造优质高端的互联网新创项目为使命，重产品体验﹑重研发质里﹑重合作共赢。我们信奉持续改进，以专属技术服务形态为理念,不断追求更高的服务质里。
            </p>

            <div class="about-btn">
              <div>MORE →</div>
            </div>
          </div>
        </div>

        <!--        2-->
        <div class="about-b">

          <div class="about-b-box"
               :style="{background: (item.sel == aboutListsel? 'linear-gradient(180deg, #4C77E8, #BBD8FA)' : ''),
                color: (item.sel == aboutListsel? '#fff' : '#061738'),
                }"
               v-for="item in aboutList" data-aos="zoom-out"
               @mouseover="mouseoverfun(item.sel)">
            <div class="about-num">
              <img :src="item.img2" alt="" v-show="item.sel != aboutListsel">
              <img :src="item.img1" alt="" v-show="item.sel == aboutListsel">
              <p>{{ item.num }}</p>
            </div>

            <h4>{{ item.tit }}</h4>

            <p>{{ item.pete }}</p>
          </div>

        </div>
      </div>
    </div>

    <!--定制开发领域-->
    <div id="nav3" class="customization-box">

      <div class="customization-center-box">
        <div class="customization-center-tit">
          <div class="customization-tit-box">
            <h3>定制开发领域</h3>
            <p>Custom development areas </p>
          </div>
          <p class="text-box">动维专业定制开发项目，从产品策划、产品研发、项目把控、
            后期运维。提供整套技术实施及方案。</p>
        </div>
        <div class="check-box">

          <div v-for='item in kalist' class="exploit-introduce" data-aos="flip-left">

            <div class="exploit-title">
              <div>
                <img :src="item.img" alt="">
              </div>
              <p>{{item.t1}}</p>
            </div>

            <p class="exploit-text">{{item.t2}}</p>

          </div>
        </div>
      </div>

    </div>

    <!--产品与服务-->
    <div id="nav4" class="serve-box">

      <!--上部分-->
      <div class="serve-top">

        <div class="serve-tit">
          <div style="width: 300px;">

          </div>

          <div class="ptimg-box">
            <img src="@/assets/img/pt.png" alt="">
          </div>

          <div class="cp-box">
            <div class="serve-tit1">
              <h3>
                产品与服务
              </h3>
              <p>Products and services</p>
            </div>
            <p class="server-text">中关汇成自主研发的产品及提供的定制化服务，丰富的实战经验可高效、低成本的研发出高质量产品；</p>
          </div>
        </div>


      </div>
      <div class="serve-bottom">
        <div class="serve-bottom-l">
          <div class="menu-box"
               data-aos="fade-right"
               :style="{backgroundColor: (cplistsel == item.sel? '#fff':''),borderLeft: (cplistsel == item.sel? '2px solid #6ABDFF':''),marginLeft: (cplistsel == item.sel? '20px':'')}"
               @mouseenter="servefun(item.sel)"
               v-for="item in cplist">{{ item.tit }}
          </div>
        </div>

        <div class="serve-bottom-r">
          <div class="serve-bottom-list" v-for="item in serveList" data-aos="fade-left">
            <div class="serve-bottom-img">
              <img :src="item.img" alt="">
            </div>

            <div class="serve-bottom-text">
              <h2>{{item.t1}}</h2>
              <p>{{item.t2}}</p>
              <div class="bq">
                <span v-for="i in item.sp3">{{i}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!--开发案例-->
    <div id="nav5">
      <div class="case-box">
        <div class="case-box-top">
          <div>
            <h3>开发经典案例</h3>
            <p>Project case</p>
          </div>
          <p class="case-box-text">中关汇成在5年的时间里，已合作多个企业客户所交付的项目均受到客户的一致认可</p>
        </div>

        <div class="case-list">

          <div class="case-list-box" data-aos="flip-down" v-for="item in caselist"
               :style="{backgroundImage: `url(${item.bgi})`,}">

            <div>
              <h3>{{ item.title }}</h3>
              <p>{{ item.Etitle }}</p>
            </div>

            <span>了解更多</span>

          </div>

        </div>
      </div>
    </div>

    <!--合作伙伴-->
    <div style="background-color:rgba(241,245,254,1);">
      <div class="case-box">
        <div class="case-box-top">
          <div>
            <h3>合作伙伴,合作共赢</h3>
            <p>Partners, win-win cooperation</p>
          </div>
          <p class="case-box-text">中关汇成在5年的时间里，已合作多个企业客户所交付的项目均受到客户的一致认可</p>
        </div>

        <div class="case-list" style="margin-top: 20px;">

          <div class="case-list-box-hz" v-for="item in hlist" data-aos="flip-up">

            <img :src="item.img" alt="">

          </div>

        </div>
      </div>
    </div>

    <!--联系我们-->
    <div id="nav6" class="contact-box">
      <div class="contact-center">
        <div class="contact-center-top">
          <h3>联系我们</h3>
          <p>contact us</p>
        </div>

        <div class="lx-list-box">
          <div class="lx-list-b" v-for="item in lxList">
            <div class="lx-list">
              <span>{{ item.text }}</span>
              <img :src="item.img" alt="">
            </div>

            <p>{{ item.xp }}</p>
          </div>
        </div>

        <div class="leave-message">
          <p>留言</p>

          <el-form ref="form" :model="form" label-width="70px">
            <el-col :span="8">
              <el-form-item label="您的姓名">
                <el-input v-model="form.name"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话">
                <el-input v-model="form.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="QQ/微信">
                <el-input v-model="form.wechat"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="24">
              <el-form-item>
                <el-input :autosize="{ minRows: 4, maxRows: 6}" type="textarea" resize="none"
                          v-model="form.desc"></el-input>
              </el-form-item>
            </el-col>

            <el-col>
              <el-form-item>
                <el-button size="mini">提交</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </div>


      </div>
    </div>


    <!--底部-->
    <div class="base-box">

      <div class="base-box-center">

        <div class="log-box">
          <img src="@/assets/img/logo.png" alt="">
        </div>

        <div class="consult-list">
          <div class="consult-box">
            <p>咨询电话：</p>
            <h4>1234-567-8912</h4>
            <p>联系地址：北京市海淀区西三环北路科原大厦B座</p>
          </div>

          <div class="consult-box-1">
            <p>公众号二维码</p>
            <img src="" alt="">
          </div>
          <div class="consult-box-1">
            <p>公众号二维码</p>
            <img src="" alt="">
          </div>
        </div>

      </div>

    </div>
    <div class="base-g">
      北京中关汇成信息科技有限公司 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Copyright © 2021 Al Right Reserved
    </div>

  </div>
</template>

<script>
import AOS from 'aos'
import "../../node_modules/aos/dist/aos.css";
import videomuiPlayer from '@/components/videomuiPlayer'
// import '@/assets/style/index.scss'

export default {
  name: 'index',
  components: {videomuiPlayer},
  data() {
    return {
      form: {},
      navtext: [
        {text: '首页', idb: 'nav1'},
        {text: '主营业务', idb: 'nav2'},
        {text: '定制开发领域', idb: 'nav3'},
        {text: '产品与服务', idb: 'nav4'},
        {text: '开发经典案例', idb: 'nav5'},
        {text: '联系我们', idb: 'nav6'},
      ],

      aboutListsel: 1,
      aboutList: [
        {
          img1: require('@/assets/img/d2.png'),
          img2: require('@/assets/img/d1.png'),
          num: '01',
          tit: '产品策划',
          pete: '好的开始是成功的一半，项目成败往 往需求和方案上就已经注定了命运。 流程图、思维导图、原型交互图',
          sel: 1,
        },
        {
          img1: require('@/assets/img/d4.png'),
          img2: require('@/assets/img/d3.png'),
          num: '02',
          tit: '定制开发/产品',
          pete: 'APP定制开发、微信小程序开发 应用系统开发、网站微站开发 智能硬件开发、物联网应用开发',
          sel: 2,
        },
        {
          img1: require('@/assets/img/d6.png'),
          img2: require('@/assets/img/d5.png'),
          num: '03',
          tit: '专属运维',
          pete: '开发线上，只是服务的开始。中关 汇成会提供一系列的后续技术策略 支持，真正成为您的专属技术团队',
          sel: 3,
        },
        {
          img1: require('@/assets/img/d8.png'),
          img2: require('@/assets/img/d7.png'),
          num: '04',
          tit: '人才服务',
          pete: '依托金领园集团多年雄厚的互联网 人力资源库积累，可提供大数据、 云计算、测试、视频特效、人工智、 设计、前端、能等的人才支持',
          sel: 4,
        },
      ],

      caselist: [
        {
          bgi: require('@/assets/img/list1.png'),
          title: '亮眼私域广告投放平台',
          Etitle: 'Bright eye private domain advertising  platform',
        },
        {
          bgi: require('@/assets/img/list2.png'),
          title: '智能光伏管理系统',
          Etitle: 'Intelligent photovoltaic management  system ',
        },
        {
          bgi: require('@/assets/img/list3.png'),
          title: '大乘天福商城',
          Etitle: 'Mahayana tianfu mall ',
        },
        {
          bgi: require('@/assets/img/list4.png'),
          title: '金领园校务系统',
          Etitle: 'Jinling garden school system',
        },
        {
          bgi: require('@/assets/img/list5.png'),
          title: '智能快递柜',
          Etitle: 'Intelligent express ark ',
        },
        {
          bgi: require('@/assets/img/list6.png'),
          title: '景区手绘地图智能导览',
          Etitle: 'Hand-drawn map intelligent navigation of  the scenic spot ',
        },
      ],

      serveList: [
        {
          img: require('@/assets/img/l1.png'),
          t1: 'AI虚拟人综合解决方案',
          t2: '2019年中关汇成自主研发电商的底层框架，多年间共开发出多款电商 相关产品。通过不断升级改造，产品已具备电商全产业平台应用。',
          sp3: ['B2B2C商城','B2B2C商城','B2B2C商城',]
        },
        {
          img: require('@/assets/img/l2.png'),
          t1: '数字藏品平台解决方案',
          t2: '2019年中关汇成自主研发电商的底层框架，多年间共开发出多款电商 相关产品。通过不断升级改造，产品已具备电商全产业平台应用。',
          sp3: ['B2B2C商城','B2B2C商城','B2B2C商城',]
        },
        {
          img: require('@/assets/img/l3.png'),
          t1: 'chatGPT智能客服解决方案',
          t2: '2019年中关汇成自主研发电商的底层框架，多年间共开发出多款电商 相关产品。通过不断升级改造，产品已具备电商全产业平台应用。',
          sp3: ['B2B2C商城','B2B2C商城','B2B2C商城',]
        },
      ],

      kalist: [
        {
          img: require('@/assets/img/k1.png'),
          t1: '系统软件开发',
          t2: '有底层架构和低代码平台，同 时提供纯定制化开发',
        },{
          img: require('@/assets/img/k2.png'),
          t1: 'APP应用开发',
          t2: '提供产品策划、技术研发、系 统运维及运营服务',
        },{
          img: require('@/assets/img/k3.png'),
          t1: '小程序开发',
          t2: '微信小程序、抖音小程序、支 付宝小程序、百度小程序',
        },{
          img: require('@/assets/img/k4.png'),
          t1: '智能软硬件开发',
          t2: '为智能硬件设备，提供软硬件 传输等应用服务',
        },{
          img: require('@/assets/img/k5.png'),
          t1: '物联网应用开发',
          t2: '5G物联场景应用开发，软硬结 合，动态高效技术服务',
        },
      ],

      hlist: [
        {img: require('@/assets/img/h1.png')},
        {img: require('@/assets/img/h2.png')},
        {img: require('@/assets/img/h3.png')},
        {img: require('@/assets/img/h4.png')},
        {img: require('@/assets/img/h5.png')},
        {img: require('@/assets/img/h6.png')},
        {img: require('@/assets/img/h7.png')},
        {img: require('@/assets/img/h8.png')},
      ],
      phone: '1234-567-8901',
      videourltime: '../src/assets/img/bg.mp4',
      lxList: [
        {
          img: require('@/assets/img/x1.png'),
          text: '地址',
          xp: '北京市海淀区西三环北路科原大厦B座'
        },
        {
          img: require('@/assets/img/x2.png'),
          text: '邮箱',
          xp: 'ZGHC@163.com'
        },
        {
          img: require('@/assets/img/x1.png'),
          text: '电话',
          xp: '1234-567-8901'
        },
      ],
      cplistsel: 1,
      cplist: [
        {tit: '自主研发产品', sel: 1},
        {tit: '智慧城市项目', sel: 2},
        {tit: '智能应用产品', sel: 3},
        {tit: '元宇宙产品', sel: 4},
      ]


    }
  },

  methods: {
    arr(ar) {
      for (var i = 0; i < ar.length - 1; i++) {
        for (var j = 0; j < ar.length - i - 1; j++) {
          if (ar[j] > ar[j + 1]) {
            let temp = ar[j]
            ar[j] = ar[j + 1]
            ar[j + 1] = temp
          }
        }
      }
    },

    arr2(ar) {
      for (let i = 0; i < ar.length - 1; i++) {
        let min = i
        for (let j = i; j < ar.length; j++) {
          if (ar[j] < ar[min]) {
            min = j
          }
        }

        if (min != i) {
          let temp = ar[i]
          ar[i] = ar[min]
          ar[min] = temp
        }
      }
    },

    qc(arr) {
      var temp = []
      for (let i = 0; i < arr.length; i++) {
        temp.indexOf(arr[i]) < 0 ? temp.push(arr[i]) : temp
      }
      return temp
    },

    qh(arr) {
      arr.reduce(
      )
    },

    mouseoverfun(sel) {
      this.aboutListsel = sel
    },
    servefun(sel) {
      this.cplistsel = sel
    },

    goposition1(id) {
      document.getElementById(id).scrollIntoView({behavior: 'smooth'})
    }

  },
  mounted() {

    AOS.init({
      offset: -80,//px，提前或延迟触发
      duration: 500,//ms，过渡时间，50-3000
      anchorPlacement: 'top-bottom',
      // anchor:'body',//body
      // debounceDelay: 50,
      // startEvent: 'DOMContentLoaded',
      // once: false,
      // easing: 'ease',//时间曲线
      // delay: 0,//s，延迟执行
      // once: false//是否仅触发一次

    })
    // let arr1 = [1, 4, 3, 6, 2]
    // let arr4 = [1, 4, 4, 6, 2]
    // this.arr2(arr1)
    // // this.arr(arr1)
    // // console.log(arr1)
    // console.log(this.qc(arr4))

    // const b = [...new Set(arr4)]
  }
}
</script>

<style>
.ce {
  width: 200px;
  height: 300px;
  background-color: red;
}

.nav-box {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 0 60px;
  background-color: rgba(11, 18, 45, 1);
}

.nav-box1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 1290px;
  margin: 0 auto;
}

.logo-box img {
  width: 100px;

}

.nav-title {
  font-size: 14px;
  color: #C7C7C7;
  padding: 15px 20px;
}

.nav-right {
  display: flex;
  align-items: center;
}

.nav-title:hover {
  cursor: pointer;
}

.phone-box {
  border-left: 2px solid #C7C7C7;
  padding: 5px 20px;
  display: flex;
  align-items: center;
}

.phone-box img {
  width: 15px;
  padding-right: 10px;
}

.phone-box div:nth-child(1) {
  display: flex;
  align-items: center;
}

.video-box {
  display: flex;
  padding-top: 80px;
  position: relative;
}

.shade {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #D2D3DE;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.6);
}

.shade h3 {
  font-size: 36px;
}

.about-center-box {
  /*//width: $width;*/
  margin: 0 auto;
  width: 1290px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about-box {
  /*position: relative;*/
  /*top: -40px;*/
  /*display: flex;*/
  /*justify-content: center;*/
  padding: 50px 0;
}

.about {
  display: flex;
  align-items: flex-end;
}

.about-1 img {
  width: 300px;
}

.about-2 {
  padding-left: 40px;
}

.about-2 h3 {
  font-size: 30px;
  color: #4468D6;
  margin-top: 0;

}

.about-2 h5 {
  font-size: 18px;
}

.about-2 p {
  font-size: 14px;
  width: 500px;
  line-height: 24px;
}

.about-btn {
  display: flex;
  justify-content: flex-end;
}

.about-btn div {
  font-size: 12px;
  padding: 5px 20px;
  background-color: rgba(75, 116, 237, 1);
  color: #fff;
}

.about-b {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 60px;
}

/*item.sel == aboutListsel*/
.about-b-box {
  width: 200px;
  height: 270px;
  padding: 20px 50px;
  color: #061738;
  box-shadow: 0px 1px 8px 0px #E5E5E5;
}

.about-b-box1 {
  background: ;

}

.about-num {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-num p {
  font-size: 40px;
}

.about-num img {
  width: 80px;
}

.about-b-box > p {
  font-size: 14px;
  line-height: 30px;

}

.customization-box {
  background-image: url('@/assets/img/kab.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;
}

.customization-box {
  margin: 0 auto;

}

.customization-tit-box {
  display: flex;
  align-items: flex-end;
}

.customization-tit-box h3 {
  font-size: 28px;
  color: #4468D6;
  margin: 0;
  padding-right: 20px;

}

.customization-tit-box p {
  font-size: 14px;
  padding-left: 20px;
  border-left: 2px solid #4468D6;
  margin: 0;
}

.text-box {
  font-size: 16px;
  color: #606078;
  width: 400px;
  line-height: 30px;
  padding-top: 10px;
}

.exploit-title {
  display: flex;
  align-items: flex-start;
}

.exploit-title img {
  width: 25px;
}

.exploit-title p {
  font-size: 16px;
  color: #606078;
  padding-left: 20px;
}

.exploit-text {
  color: #606078;
  font-size: 14px;
  padding-top: 20px;
}

.exploit-introduce {
  width: 200px;
  height: 120px;
  padding: 30px;
  background: rgba(249, 248, 254, 0);
  border: 1px solid #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(201, 201, 201, 0.32);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.exploit-title div {
  padding-bottom: 10px;
  border-bottom: 2px solid #3677D5;
}

.check-box {
  width: 787px;
  display: flex;
  flex-wrap: wrap;
}

.check-box:before {
  content: '';
  width: 262px;
}

.customization-center-box {
  width: 1290px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}

.customization-center-tit {
  padding-top: 70px;
}

.serve-top {
  background-image: url('@/assets/img/t1.png');
  background-size: cover;
  background-repeat: no-repeat;
  padding: 50px 0;
}

.serve-tit {
  width: 1290px;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.serve-tit1 {
  display: flex;
  align-items: flex-end;
}

.serve-tit1 h3 {
  margin: 0;
  font-size: 28px;
  padding-right: 20px;
  color: #FFFFFF;
}

.serve-tit1 p {
  font-size: 14px;
  padding-left: 20px;
  border-left: 2px solid #E2E2E2;
  margin: 0;
  color: #E2E2E2;
}

.serve-box {
  background-color: rgba(241, 245, 254, 1);
}

.server-text {
  font-size: 14px;
  color: #E2E2E2;
  line-height: 30px;
  padding-top: 10px;
}

.ptimg-box {
  width: 300px;
  position: absolute;
  left: 0;
  top: -50px;
}

.ptimg-box img {
  width: 100%;
}

.cp-box {
  width: 700px;
}

.serve-bottom {
  width: 1290px;
  margin: 0 auto;
  display: flex;
}

.menu-box {
  width: 180px;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 600;
  box-sizing: border-box;
}

.serve-bottom-l {
  padding: 200px 70px 0;
}

.serve-bottom-r {
  width: 700px;
  margin-left: 100px;
  padding: 30px 0;
}

.serve-bottom-list {
  padding: 20px;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0px 1px 8px 0px rgba(229, 229, 229, 0.7);
  margin-bottom: 20px;
}


.serve-bottom-img img {
  width: 80px;
}

.serve-bottom-text {
  padding-left: 30px;
}

.serve-bottom-text h2 {
  font-size: 18px;
}

.serve-bottom-text p {
  font-size: 14px;
}

.serve-bottom-text span {
  font-size: 12px;
  padding: 3px 5px;
  border: 1px solid #D3D3D3;
  color: #D3D3D3;
  margin-right: 10px;
}

.bq {
  padding-top: 10px;
  display: flex;
}

.case-box {
  width: 1290px;
  margin: 0 auto;
}

.case-box-top div {
  display: flex;
  align-items: flex-end;
}

.case-box-top div h3 {
  font-size: 28px;
  color: #4468D6;
  margin: 0;
  padding-right: 20px;
}

.case-box-top div p {
  font-size: 14px;
  padding-left: 20px;
  border-left: 2px solid #4468D6;
  margin: 0;
}

.case-box-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.case-box-text {
  font-size: 16px;
  color: #606078;
  line-height: 30px;
  padding-top: 10px;
}

.case-box {
  padding: 50px 0;
}

.case-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.case-list-box {
  width: 400px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background-size: cover;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 30px;
}

.case-list-box h3 {
  margin: 10px 0;
  color: #FFFFFF;
  font-size: 16px;
}

.case-list-box p {
  color: #FFFFFF;
  font-size: 14px;
}

.case-list-box span {
  padding: 10px 20px;
  font-size: 12px;
  border: 1px solid #F1F5FE;
  color: #F1F5FE;
  width: 80px;
  text-align: center;
}

.case-list-box-hz {
  width: 300px;
}

.case-list-box-hz img {
  width: 100%;
}

.contact-box {
  background-image: url('../assets/img/lx1.png');
  background-size: cover;
  padding: 50px 0;
}

.contact-center {
  width: 1290px;
  margin: 0 auto;
}

.contact-center-top {
  display: flex;
  align-items: flex-end;
  padding-bottom: 20px;
}

.contact-center-top h3 {
  margin: 0;
  font-size: 28px;
  color: #FFFFFF;
  padding-right: 20px;
}

.contact-center-top p {
  padding-left: 20px;
  color: #E2E2E2;
  font-size: 14px;
  border-left: 1px solid #E2E2E2;
}

.contact-center {
  /*padding: 0 20px;*/
}

.lx-list-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lx-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #E2E2E2;
  width: 250px;
}

.lx-list img {
  width: 15px;
}

.lx-list span {
  font-size: 14px;
  color: #E2E2E2;
}

.lx-list-b p {
  font-size: 14px;
  padding: 10px 0 0;
  color: #E2E2E2;
}

.leave-message {
  padding: 20px 0 0;
  color: #E2E2E2;
  font-size: 14px;
}

form {
  overflow: auto;
}

.el-form-item__label {
  color: #E2E2E2;
}

.base-box {
  background-color: rgba(37, 40, 43, 1);
  padding: 50px 0;
}

.base-box-center {
  width: 1290px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.log-box img {
  width: 200px;
}

.consult-list {
  display: flex;
  align-items: center;
}

.consult-box {
  color: #E2E2E2;
  padding-right: 20px;
}

.consult-box p {
  font-size: 12px;
}

.consult-box h4 {
  font-size: 18px;
  margin: 10px 0;
}

.consult-box-1 {
  display: flex;
  align-items: center;
  color: #E2E2E2;
  padding: 30px 20px;
  border-left: 1px solid #E2E2E2;
}

.consult-box-1 p {
  font-size: 14px;
}

.consult-box-1 img {
  width: 80px;
}

.base-g {
  background-color: rgba(33, 36, 39, 1);
  text-align: center;
  padding: 20px 0;
  color: #C2C2C2;
  font-size: 12px;
}
</style>
